import isMobile from 'lib/isMobile';
import isTablet from 'lib/isTablet';
import reactUtils from 'lib/reactUtils';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { from, fromEvent, interval } from 'rxjs';
import { debounce, mergeMap } from 'rxjs/operators';
import SimpleBarReact from 'simplebar-react';
import UAParser from 'ua-parser-js';
import css from './NavScrollbar.scss';

const initialState = {
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  width: 0,
  height: 0,
};

const NavScrollbar = (props) => {
  const debug = false;
  const router = useRouter();
  const { childRef, listenScroll = false } = props;
  const [clientRect, setClientRect] = useState(initialState);
  const divDOM = useRef(null);
  const scrollDOM = useRef(null);
  const frameDOM = useRef(null);

  const [tablet, setTablet] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [ua, setUA] = useState(null);
  const refreshScreensize = useCallback(() => {
    setMobile(isMobile());
    setTablet(isTablet());
    setUA(new UAParser());
  }, []);

  useEffect(() => {
    // Window not defined
    if (!global.window) return;

    // Resize
    fromEvent(global.window, 'resize').pipe(debounce(() => interval(250))).subscribe(() => {
      const wrapperDOM = childRef || divDOM;
      if (!wrapperDOM) return;
      if (!wrapperDOM.current) return;
      const rect = (wrapperDOM.current.getBoundingClientRect());
      setClientRect(rect);
      refreshScreensize();
    });
    global.window.dispatchEvent(new Event('resize'));

    const scrollElement = (() => {
      try {
        return scrollDOM.current.getScrollElement();
      } catch (_err) {
        return null;
      }
    })();
    if (!scrollElement) return;

    // Scroll
    const scrollEvents = [
      [global.window, 'scroll'],
      [global.window, 'wheel'],
      [global.window, 'gesturechange'],
      [global.window, 'touchmove'],
      [global.window, 'touchend'],
      [scrollElement, 'scroll'],
      [scrollElement, 'wheel'],
      [scrollElement, 'gesturechange'],
      [scrollElement, 'touchmove'],
      [scrollElement, 'touchend'],
    ];
    from(scrollEvents).pipe(
      mergeMap(ev => fromEvent(...ev)),
      debounce(() => interval(250)),
    ).subscribe(() => {
      if (!scrollDOM) return;
      if (!scrollDOM.current) return;
      const scrollElement = (() => {
        try {
          return scrollDOM.current.getScrollElement();
        } catch (_err) {
          return null;
        }
      })();
      if (!scrollElement) return;

      const viewRect = scrollElement.getBoundingClientRect();

      const scrollViewHeight = viewRect.height;
      const scrollViewTop = viewRect.top;
      const scrollTopThreshold = 0.5 * scrollViewHeight;

      frameDOM.current.style.height = `${scrollTopThreshold}px`;

      const h2List = Object.values(scrollElement.querySelectorAll('h2[id]')).filter((h2) => h2.id.search(/-title$/) !== -1);

      if (!h2List) return;

      let scrolledH2 = null;

      h2List.map((h2Item) => {
        const { top } = h2Item.getBoundingClientRect();
        const h2Top = Math.max(-1, top - scrollViewTop);
        if (h2Top > scrollTopThreshold) {
          return;
        }
        scrolledH2 = h2Item;
      });

      const localUA = new UAParser();
      if (localUA && localUA.getOS() && localUA.getOS().name && localUA.getOS().name === 'iOS') {
        return;
      }

      scrollElement.querySelectorAll('h2[id]').forEach((h2) => {
        if (debug) {
          h2.style.backgroundColor = 'red';
        }
      });

      if (!scrolledH2) {
        scrolledH2 = h2List[0];
      }

      if (!scrolledH2) return;
      if (!scrolledH2.id) return;

      if (debug) {
        scrolledH2.style.backgroundColor = 'green';
      }

      Object.values(document.getElementsByClassName('SubMenuItemMobile')).map((subMenu) => {
        // subMenu.innerText = scrolledH2.innerHTML.replace(/<br>/, ' ');
      });

      const previousHash = location.hash;
      const newHash = `#${scrolledH2.id.replace('-title', '')}`;
      if (previousHash !== newHash) {
        const baseUrl = window.location.href.split('#')[0];
        // router.replace(`${baseUrl}${newHash}`, null, { shallow: true });
        history.pushState(null, null, newHash);
        const event = new Event('scrollHashChange');
        window.dispatchEvent(event);
      }

    });
    global.window.dispatchEvent(new Event('scroll'));
  }, [global.window]);

  return (
    <div
      {...reactUtils.restrictReactProps(props)}
      className={[props.className, css.NavScrollbar, 'NavScrollbar'].join(' ')}
      ref={childRef || divDOM}
    >
      <div
        className={css.Frame, (debug? css.Debug : '')}
        ref={frameDOM}
      />
      <SimpleBarReact
        ref={scrollDOM}
        id={'scrollDOM'}
        style={{
          height: `calc( 100vh - ${clientRect.top}px)`,
          minWidth: `${clientRect.width}px`
        }}
      >
        {props.children}
      </SimpleBarReact>
    </div>
  );
};

export default NavScrollbar;
