import React from 'react';
import packageJson from '../../../package.json';
import css from './AppFooter.scss';
import config from 'config';

const AppFooter = (props) => {
  return (
    <div
      {...props}
      className={[props.className, css.AppFooter].join(' ')}
    >
      <div
        className={css.Version}
      >
        v{config.packageJson.version}
      </div>
    </div>
  );
};

export default AppFooter;
