import update from 'immutability-helper';

const propsBlacklist = [
  'childRef',
  'listenScroll',
];

const restrictReactProps = (props) => {
  return update(props, {
    $unset: propsBlacklist,
  });
};

const reactUtils = {
  restrictReactProps,
};

export default reactUtils;
