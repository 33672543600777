import Header from 'components/Domain/Header';
import React from 'react';
import css from './AppBar.scss';

const AppBar = (props) => {
  return (
    <div
      {...props}
      className={[props.className, css.AppBar].join(' ')}
    >
      <Header/>
    </div>
  );
};

export default AppBar;
