import CGUModal from 'components/Domain/CGUModal';
import CookieModal from 'components/Domain/CookieModal';
import TrackingMatomo from 'components/Domain/TrackingMatomo';
import TrackingGoogle from 'components/Domain/TrackingGoogle';
import AppBar from 'components/Layout/AppBar';
import AppBody from 'components/Layout/AppBody';
import AppFooter from 'components/Layout/AppFooter';
import config from 'config';
import { AnimatePresence } from 'framer-motion';
import useClientRect from 'hooks/useClientRect';
import isXS from 'lib/isXS';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { fromEvent, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';
import css from 'styles/root.scss';
import Cookies from 'universal-cookie';

const handleExitComplete = () => {
  if (typeof window !== 'undefined') {
    window.scrollTo({ top: 0 });
    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 250);
  }
};

const MainApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const divDOM = useRef(null);
  const bodyDOM = useRef(null);
  const clientRect = useClientRect(divDOM);
  const [scale, setScale] = useState(1);
  const [CookieModalIsOpen, setCookieModalIsOpen] = useState(false);
  const [CGUModalIsOpen, setCGUModalIsOpen] = useState(false);
  const [enableTracker, setEnableTracker] = useState(false);

  const getCGUState = useCallback(() => new Cookies().get('cgu'), [global.window]);
  const getCookieState = useCallback(() => new Cookies().get('cookie'), [global.window]);
  const getStatisticsState = useCallback(() => new Cookies().get('statistics'), [global.window]);
  const getMarketingState = useCallback(() => new Cookies().get('marketing'), [global.window]);

  useEffect(() => {
    setScale(isXS() ? 0.5 : 1);
    setCookieModalIsOpen(!getCookieState());
  }, []);

  useEffect(() => {
    const cookieState = getCookieState();
    if (!cookieState) return;
    if (CookieModalIsOpen) return;
    setCGUModalIsOpen(!getCGUState());
  }, [CookieModalIsOpen]);

  const appExitComplete = () => {
    bodyDOM.current.getElementsByClassName('simplebar-content-wrapper')[0].scrollTop = 0;
    handleExitComplete();
  };

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  });

  useEffect(() => {
    fromEvent(global.window, 'resize').pipe(debounce(() => interval(250))).subscribe(() => {
      setScale(isXS() ? 0.65 : 1);
    });
    fromEvent(global.window, 'set-cookie').pipe(debounce(() => interval(250))).subscribe(() => {
      setEnableTracker(!!getStatisticsState());
    });
  }, [global.window]);

  return (
    <div
      className={css.root}
    >
      <Head>
        <title>
          {config.title}
        </title>
        <meta
          id="viewport"
          name="viewport"
          content={`minimum-scale=${scale}, initial-scale=${scale}, width=device-width`}
        />
      </Head>
      <CookieModal
        isOpen={CookieModalIsOpen}
        setIsOpen={setCookieModalIsOpen}
        preventClose={true}
      />
      <CGUModal
        isOpen={CGUModalIsOpen}
        setIsOpen={setCGUModalIsOpen}
        preventClose={true}
      />
      <div
        className={[css.Body].join(' ')}
      >
        <AppBar/>
        <AppBody
          childRef={bodyDOM}
        >
          <React.Fragment>
            <div
              ref={divDOM}
            />
            <AnimatePresence
              exitBeforeEnter
              onExitComplete={appExitComplete}
            >
              <Component
                key={router.route}
                top={clientRect.bottom}
                {...pageProps}
              />
            </AnimatePresence>
          </React.Fragment>
        </AppBody>
        <AppFooter/>
      </div>
      {enableTracker && (
        <>
          <TrackingMatomo/>
          <TrackingGoogle />
        </>
      )}
    </div>
  );
};

export default MainApp;
