import styleVars from 'styles/vars.scss';

const isXS = () => {
  const XSWidth = parseInt(styleVars.XSWidth, 10);
  const screenWidth = (global.window ? (() => {
    const globalWidth = global.window.innerWidth;
    const viewportContent = document.getElementById('viewport').content;
    const scales = viewportContent.match(/=([^,]+)/gmi);
    if (scales.length > 0) {
      const scale = parseFloat(scales[0].replace('=', ''), 10);
      return globalWidth * scale;
    }
    return globalWidth;
  })() : -1);
  return screenWidth === -1 ? false : (screenWidth <= XSWidth);
};

export default isXS;
