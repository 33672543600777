import Modal from 'components/Layout/Modal';
import SelectButton from 'components/Layout/SelectButton';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import SimpleBarReact from 'simplebar-react';
import cssGlobal from 'styles/global.scss';
import colors from 'styles/vars.scss';
import Cookies from 'universal-cookie';
import css from './CookieModal.scss';

const CookieModal = (props) => {
  const [showCustomize, setShowCustomize] = useState(false);
  const [authorizeStatistics, setAuthorizeStatistics] = useState(false);
  const [authorizeMarketing, setAuthorizeMarketing] = useState(false);

  useEffect(() => {
    const event = new Event('set-cookie');
    window.dispatchEvent(event);
  });

  return (
    <Modal
      preventClose={props.preventClose || false}
      isOpen={props.isOpen}
      setIsOpen={(show) => {
        props.setIsOpen(show);
      }}
      className={[props.className, css.CookieModal].join(' ')}
    >
      <p>
        Société nationale SNCF utilise des cookies strictement nécessaires au fonctionnement du site et des cookies statistiques.
        Par ailleurs, des cookies marketing (cookies tiers) sont déposés par Youtube afin de vous proposer des vidéos et de réaliser
        des statistiques de visite. A l’exception des cookies strictement nécessaires au fonctionnement du site, vous pouvez contrôler
        ceux que vous souhaitez activer. Vous pouvez modifier vos préférences en cliquant sur « Personnaliser ».
        &nbsp;<Link
          href={'/documents/cookies.pdf'}
        >
          <a
            target={'_blank'}
          >
            En savoir plus
          </a>
        </Link>
      </p>
      <nav>
        <button
          className={cssGlobal.Action}
          onClick={() => {
            const cookies = new Cookies();
            window?._mtm?.push({'event': 'statistics_consent_granted'});
            cookies.set('statistics',
              decodeURI(`Accepted on ${new Date().toISOString()}`),
              {
                path: '/',
                expires: new Date(Date.now() + 31536000000)
              }
            );
            cookies.set('marketing',
              decodeURI(`Accepted on ${new Date().toISOString()}`),
              {
                path: '/',
                expires: new Date(Date.now() + 31536000000)
              }
            );
            cookies.set('cookie',
              decodeURI(`Accepted on ${new Date().toISOString()}`),
              {
                path: '/',
                expires: new Date(Date.now() + 31536000000)
              }
            );
            props.setIsOpen(false);
          }}
        >
          OK, tout accepter
        </button>
        <button
          className={cssGlobal.Action}
          onClick={() => {
            setShowCustomize(true);
          }}
        >
          Personnaliser
        </button>
        <button
          className={cssGlobal.Action}
          onClick={() => {
            const cookies = new Cookies();
            cookies.set('cookie',
              decodeURI(`Accepted on ${new Date().toISOString()}`),
              {
                path: '/',
                expires: new Date(Date.now() + 31536000000)
              }
            );
            props.setIsOpen(false);
          }}
        >
          Tout refuser
        </button>
      </nav>
      {showCustomize && (
        <div
          className={css.Customize}
        >
          <SimpleBarReact
            autoHide={false}
            forceVisible={'y'}
            style={{
              maxHeight: '50vh'
            }}
          >
            <h2>
              CE SITE WEB UTILISE DES COOKIES.
            </h2>
            <p>
              Les cookies déposés sur le site servent au bon fonctionnement du site, à collecter des statistiques en vue
              d'optimiser le site et à effectuer le suivi des visiteurs au travers de sites web. Vous trouverez
              ci-dessous
              les informations relatives à la nature des cookies déposés. Vous avez la possibilité d'accepter ou de
              refuser
              le dépôt des cookies statistiques et des cookies marketing.
            </p>
            <h3>
              Nécessaires
            </h3>
            <p>
              Ces cookies permettent au Site de fonctionner de manière optimale.
            </p>
            <div
              className={css.Scrollable}
            >
              <table
                cellSpacing={0}
                cellPadding={0}
                border={0}
              >
                <thead>
                <tr>
                  <th>
                    Nom du Cookie
                  </th>
                  <th>
                    Finalité
                  </th>
                  <th>
                    Durée de conservation
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    cgu
                  </td>
                  <td>
                    Ce Cookie a pour finalité la sauvegarde du choix de l’utilisateur en matière de consentement des CGU
                  </td>
                  <td>
                    12 mois
                  </td>
                </tr>
                <tr>
                  <td>
                    cookie
                  </td>
                  <td>
                    Ce Cookie a pour finalité la sauvegarde du choix de l’utilisateur en matière de consentement des
                    Cookies
                  </td>
                  <td>
                    12 mois
                  </td>
                </tr>
                <tr>
                  <td>
                    statistics
                  </td>
                  <td>
                    Ce Cookie a pour finalité la sauvegarde du choix de l’utilisateur en matière de consentement des
                    Cookies statistiques
                  </td>
                  <td>
                    12 mois
                  </td>
                </tr>
                <tr>
                  <td>
                    marketing
                  </td>
                  <td>
                    Ce Cookie a pour finalité la sauvegarde du choix de l’utilisateur en matière de consentement des
                    Cookies marketing
                  </td>
                  <td>
                    12 mois
                  </td>
                </tr>
                <tr>
                  <td>
                    matomo_sessid
                  </td>
                  <td>
                    Matomo définit ce cookie lorsque la fonction d’opt-out Matomo est utilisée, pour éviter les problèmes de sécurité cross-site request forgery (CSRF). session
                  </td>
                  <td>
                    14 jours
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <h3>
              Statistiques
            </h3>
            <SelectButton
              status={authorizeStatistics}
              setStatus={setAuthorizeStatistics}
            />
            <p>
              Ces cookies aident le propriétaire du Site, par la collecte et la communication d'informations de manière
              anonyme, à comprendre comment les utilisateurs interagissent avec le Site.
            </p>
            <div
              className={css.Scrollable}
            >
              <table
                cellSpacing={0}
                cellPadding={0}
                border={0}
              >
                <thead>
                <tr>
                  <th>
                    Nom du Cookie
                  </th>
                  <th>
                    Finalité
                  </th>
                  <th>
                    Durée de conservation
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    _pk_ses
                  </td>
                  <td>
                    Pour stocker un ID de session unique session
                  </td>
                  <td>
                    30 minutes
                  </td>
                </tr>
                <tr>
                  <td>
                    _pk_id
                  </td>
                  <td>
                    Pour stocker un ID d'utilisateur unique
                  </td>
                  <td>
                    13 mois
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <h3>
              Marketing
            </h3>
            <SelectButton
              status={authorizeMarketing}
              setStatus={setAuthorizeMarketing}
            />
            <p>
              Les cookies marketing sont utilisés pour effectuer le suivi des visiteurs au travers des sites web. Les
              cookies marketing du Site sont des cookies tiers déposés par Youtube (politique de confidentialité :
              &nbsp;<Link
              href={'https://policies.google.com/privacy?hl=fr&gl=fr'}
            >
              <a
                target="_blank"
                style={{
                  color: colors.black
                }}
              >
                https://policies.google.com/privacy?hl=fr&gl=fr
              </a>
            </Link>) et hébergés par l’interface. Le Site n’a pas la main mise sur
              les informations récupérées par les tiers car ils agissent pour leur propre marque.
            </p>
            <div
              className={css.Scrollable}
            >
              <table
                cellSpacing={0}
                cellPadding={0}
                border={0}
              >
                <thead>
                <tr>
                  <th>
                    Nom du Cookie
                  </th>
                  <th>
                    Finalité
                  </th>
                  <th>
                    Durée de conservation
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    VISITOR_INFO1_LIVE (Youtube)
                  </td>
                  <td>
                    Traceur de fonctionnement (estimer la bande passante)
                  </td>
                  <td>
                    6 mois
                  </td>
                </tr>
                <tr>
                  <td>
                    YSC (Youtube)
                  </td>
                  <td>
                    Traceur de mesure d’audience (compter les visiteurs uniques)
                  </td>
                  <td>
                    session
                  </td>
                </tr>
                <tr>
                  <td>
                    GPS (Youtube)
                  </td>
                  <td>
                    Traceur de mesure d’audience (localisation)
                  </td>
                  <td>
                    session
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <button
              className={cssGlobal.Action}
              onClick={() => {
                const cookies = new Cookies();
                if (authorizeStatistics) {
                  window?._mtm?.push({'event': 'statistics_consent_granted'});
                  cookies.set('statistics',
                    decodeURI(`Accepted on ${new Date().toISOString()}`),
                    {
                      path: '/',
                      expires: new Date(Date.now() + 31536000000)
                    }
                  );
                }
                if (authorizeMarketing) {
                  cookies.set('marketing',
                    decodeURI(`Accepted on ${new Date().toISOString()}`),
                    {
                      path: '/',
                      expires: new Date(Date.now() + 31536000000)
                    }
                  );
                }
                cookies.set('cookie',
                  decodeURI(`Accepted on ${new Date().toISOString()}`),
                  {
                    path: '/',
                    expires: new Date(Date.now() + 31536000000)
                  }
                );
                props.setIsOpen(false);
              }}
            >
              Accepter
            </button>
          </SimpleBarReact>
        </div>
      )}
    </Modal>
  );
};

CookieModal.getInitialProps = async () => {
  return {
    isOpen: false,
    setIsOpen: () => {},
    preventClose: true,
  };
};

export default CookieModal;
