import { AnimateSharedLayout, motion } from 'framer-motion';
import React from 'react';
import css from './SelectButton.scss';

const SelectButton = ({ className, status, setStatus }) => {
  return (
    <div
      className={[className, css.SelectButton].join(' ')}
    >
      <AnimateSharedLayout>
      <button
        className={[css.Button].join(' ')}
        onClick={() => {
          setStatus(true);
        }}
      >
        <span>
          Autoriser
        </span>
        {status && (
          <motion.div
            layoutId="select"
            className={css.Selected}
            initial={false}
            transition={{
              type: 'spring',
              stiffness: 500,
              damping: 30,
              duration: 500
            }}
          >
            Autoriser
          </motion.div>
        )}
      </button>
      <button
        className={[css.Button].join(' ')}
        onClick={() => {
          setStatus(false);
        }}
      >
        <span>
          Interdire
        </span>
        {!status && (
          <motion.div
            layoutId="select"
            className={css.Selected}
            initial={false}
            transition={{
              type: 'spring',
              stiffness: 500,
              damping: 30,
              duration: 500
            }}
          >
            Interdire
          </motion.div>
        )}
      </button>
      </AnimateSharedLayout>
    </div>
  );
};

export default SelectButton;
