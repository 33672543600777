import Modal from 'components/Layout/Modal';
import Link from 'next/link';
import React from 'react';
import cssGlobal from 'styles/global.scss';
import colors from 'styles/vars.scss';
import Cookies from 'universal-cookie';
import css from './CGUModal.scss';

const CGUModal = (props) => {
  return (
    <Modal
      preventClose={props.preventClose || false}
      isOpen={props.isOpen}
      setIsOpen={(show) => {
        props.setIsOpen(show);
      }}
      className={[props.className, css.CGUModal].join(' ')}
    >
      <h2>
        Conditions générales d'utilisation
      </h2>
      <p>
        <Link
          href={'/documents/cgu.pdf'}
        >
          <a
            target="_blank"
            style={{
              color: colors.black
            }}
            download
          >
            Cliquez ici
          </a>
        </Link>
        &nbsp;pour consulter les CGU du site « Livret d’accueil »
      </p>
      <nav>
        <button
          className={cssGlobal.Action}
          onClick={() => {
            const cookies = new Cookies();
            cookies.set('cgu',
              decodeURI(`Accepted on ${new Date().toISOString()}`),
              {
                path: '/',
                expires: new Date(Date.now() + 31536000000)
              }
            );
            props.setIsOpen(false);
          }}
        >
          J’ai lu les CGU intégralement et je les accepte.
        </button>
      </nav>
    </Modal>
  );
};

CGUModal.getInitialProps = async () => {
  return {
    isOpen: false,
    setIsOpen: () => {},
    preventClose: true,
  };
};

export default CGUModal;
