import NavScrollbar from 'components/Layout/NavScrollbar';
import React, { useRef } from 'react';
import css from './AppBody.scss';

const AppBody = (props) => {
  const appDOM = props.childRef || useRef(null);
  return (
    <NavScrollbar
      {...props}
      className={[props.className, css.AppBody].join(' ')}
      childRef={appDOM}
      listenScroll={true}
    >
      {props.children}
    </NavScrollbar>
  );
};

export default AppBody;
