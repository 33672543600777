import React, { useCallback, useEffect } from 'react';
import Cookies from 'universal-cookie';

const TrackingMatomo = () => {
  const scriptId = 'Matomo';
  const getStatisticsState = useCallback(() => new Cookies().get('statistics'), [global.window]);

  useEffect(() => {
    if ((!getStatisticsState())) return null;
    if (!!document.getElementById(scriptId)) return null;

    // const scriptTagManager = document.createElement('script');
    // scriptTagManager.id = scriptId;
    // scriptTagManager.async = true;
    // scriptTagManager.innerHTML = '' +
    //   'var _mtm = window._mtm = window._mtm || [];\n' +
    //   '_mtm.push({"mtm.startTime": (new Date().getTime()), "event": "mtm.Start"});\n' +
    //   'var d=document, g=d.createElement("script"), s=d.getElementsByTagName("script")[0];\n' +
    //   'g.async=true; g.src="https://cdn.matomo.cloud/livretaccueilsncf.matomo.cloud/container_bApBjCxj.js"; s.parentNode.insertBefore(g,s);\n' +
    //   '';
    // scriptTagManager.onloadeddata = () => {
    // };
    // document.body.insertBefore(scriptTagManager, document.body.childNodes[0]);yarn 
  }, [global.window]);

  return null;
};

export default TrackingMatomo;
