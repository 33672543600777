import React, { useCallback, useEffect } from 'react';
import Cookies from 'universal-cookie';

const TrackingGoogle = () => {
  const getStatisticsState = useCallback(() => new Cookies().get('statistics'), [global.window]);

  useEffect(() => {
    if ((!getStatisticsState())) return null;
    if (!!document.getElementById('GTM')) return null;

    const scriptTagManager = document.createElement('script');
    scriptTagManager.async = true;
    scriptTagManager.src = 'https://www.googletagmanager.com/gtag/js?id=G-M554PGKE6Q';
    document.body.insertBefore(scriptTagManager, document.body.childNodes[0]);

    scriptTagManager.onload = () => {
      const scriptDataLayer = document.createElement('script');
      scriptDataLayer.id = 'GTM';
      scriptDataLayer.async = true;
      scriptDataLayer.innerHTML = 'window.dataLayer = window.dataLayer || [];\n' +
        '      function gtag(){dataLayer.push(arguments);}\n' +
        '      gtag(\'js\', new Date());\n' +
        '      gtag(\'config\', \'G-M554PGKE6Q\', { \'cookie_expires\': 31536000, \'anonymize_ip\': true });';
      document.body.insertBefore(scriptDataLayer, document.body.childNodes[0]);

      scriptDataLayer.onload = () => {

      };
    };
  }, [global.window]);

  return null;
};

export default TrackingGoogle;
